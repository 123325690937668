.widget-container {
  .registration-container {
    display: flex;
    justify-content: center;
    align-items: center;

    .register-link {
        cursor: pointer;
        color: var(--primary-hover);

        &:hover {
            color: var(--primary);
        }
    }
  }
}

.js-help-link {
  display: none;
}

#okta-sign-in.auth-container .link.help {
  display: none;
}