body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .action-button {
    display: flex;
    gap: .3rem;
  }

  .section-title {
    display: flex;
    align-items: center;
  }
}

.form-group-horizontal {
  display: flex;
  flex-direction: row !important;
  gap: 1rem !important;
}

.row-bold {
  font-weight: bold;
}

.monitoring-row {
  cursor: default;
}

.clickable {
  cursor: pointer;
}

.underline {
  text-decoration: underline;
}

.row-colored-bold {
  font-weight: bold;
  color: #1976d2;
}