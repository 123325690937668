.dashboard-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .dashboard-row {
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
  }
}