.title-analysis-table-color {
  background-color: #1976d2;
  color: #ffffff;
}

.title-analysis-table-color.Mui-hovered {
  background-color: #3685d3 !important;
  color: #ffffff;
}

.title-analysis-table-color.Mui-selected {
  background-color: #4f91d3 !important;
  color: #ffffff;
}

.title-analysis-table-color.Mui-selected.Mui-hovered {
  background-color: #6ba0d5 !important;
  color: #ffffff;
}

.selection-element {
  display: flex;
}

.card-monitoring {
  max-width: calc(100vw - 113px);
  height: calc(100vh - 127px);
}