.card-summary {
  max-width: calc(100vw - 113px);
  height: calc(100vh - 127px);

  .summary-element {
    margin: 0 1.5rem;

    .summary-selection-element {
      width: 100%;
      margin-bottom: 2rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .groups-selection {
        display: flex;
      }

      .picked-group {
        display: flex;
        align-items: center;
        justify-content: center;

        .group-text {
          font-size: 17px;
          color: #939393;
        }

        .group-cmr {
          margin-left: .5rem;
          font-size: 35px;
          font-weight: 500;
          color: #1976d2;
        }
      }
    }
  }
}