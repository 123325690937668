 .row-style {
  background-color: #1976d2;
   font-weight: bold;
  color: #ffffff;
}

 .row-style.Mui-hovered {
  background-color: #3685d3 !important;
  color: #ffffff;
}

 .row-style.Mui-selected {
  background-color: #4f91d3 !important;
  color: #ffffff;
}

 .row-style.Mui-selected.Mui-hovered {
  background-color: #6ba0d5 !important;
  color: #ffffff;
}